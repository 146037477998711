import React, { useState } from 'react';

import { Popover } from 'react-tiny-popover';
import css from './CardSources.module.css';
import OutlineSource from '../../../../../icons/SourceOutline';
import Source from '../../../Source/Source';

function SourcesMenu({ sources, showMore }) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['bottom']}
      containerStyle={{ zIndex: 99 }}
      onClickOutside={() => setIsPopoverOpen(false)}
      content={
        <ul className={css.menu}>
          {sources.map((source) => (
            <li key={source}>
              <Source src={source} />
            </li>
          ))}
        </ul>
      }
    >
      <div className={css.sourcesInMenu} onClick={() => setIsPopoverOpen(true)}>
        {showMore && '+'}
        {sources.length}
        {!showMore && <OutlineSource />}
      </div>
    </Popover>
  );
}

function CardSources({ sources = [], ...props }) {
  if (sources.length === 0) return null;

  const [head, ...tail] = sources;

  return (
    <div {...props} className={css.sources}>
      <div className={css.largeScreen}>
        <Source src={head} />
        {tail.length > 0 && <SourcesMenu sources={tail} showMore />}
      </div>
      <div className={css.smallScreen}>
        <SourcesMenu sources={sources} />
      </div>
    </div>
  );
}

export default CardSources;
